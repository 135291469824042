import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from 'react-redux';
import methodModel from "../../methods/methods";
import { bookingSystemType } from "../../models/type.model";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import ImageUpload from "../../components/common/ImageUpload";
import { Tooltip } from "antd";
import environment from "../../environment";
import { Editor } from "@tinymce/tinymce-react";
import { TrashIcon } from "@heroicons/react/20/solid";
import { BsTrash3 } from "react-icons/bs";

const AddEditBookingSystem = () => {

    const defaultvalue = () => {
        let keys = { ...bookingSystemType }
        Object.keys(bookingSystemType).map(itm => {
            if (itm != 'permissions') keys[itm] = ''
        })
        keys.status = 'active'
        return keys
    }
    const { id } = useParams()
    const [form, setform] = useState({ ...bookingSystemType, visible_to_all :true})
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const formValidation = [
        { key: 'name', required: true }
    ]
    const [images, setImages] = useState({ logo: '' });

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        if(!form?.instructions){

            return
        }
        let method = 'post'
        let url = 'api/bookingsystem'
        let value = {
            ...form,
            ...images,
        }
        if (value.id) {
            method = 'put'
            url = 'api/bookingsystem/update'
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                history("/bookingSystem")
            }
            loader(false)
        })
    }

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get('api/bookingsystem/detail', { id }).then(res => {
                if (res.success) {
                    let value = res.data
                    let payload = bookingSystemType

                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })
                    if (value.permissions) {
                        payload.permissions = { ...value.permissions[0] }
                        // payload.permissions={ ...payload.permissions,...value.permissions}
                    }

                    setform({
                        ...payload
                        ,
                        visible_to_all: value?.visible_to_all,
                        domains: value?.domains||[]
                    })

                    images.logo = value?.logo
                    setImages({ ...images })
                }
                loader(false)
            })
        } else {
            setform(defaultvalue())
        }

    }, [id])

    const onSelect = (e) => {
    }

    const onRemove = (e) => {
    }

    const imageResult = (e, key) => {
        images[key] = e.value
        setImages(images)
    }


    const Domainshandler=(index,value)=>{
        const formdata=form;
        const VisibleData=form?.domains;
        VisibleData[index]=value;
        setform({...form,domains:VisibleData});
    }
    const HandleAddOrDeleter=(e,type='add',index=0)=>{
        e.preventDefault();
        if(type=='add'){
            const VisibleDomains=form?.domains;
            setform({...form,domains:[...form.domains,'']})
        }else{
            const visibleData=form?.domains.filter((item,ind)=>ind!=index)
            setform({...form,domains:[...visibleData]})
        }
    }
    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">

                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <Link to="/bookingSystem" className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border  transition-all   mr-3"><i className='fa fa-angle-left text-lg'></i></Link>
                        </Tooltip>
                        <div>
                            <h3 className="text-2xl font-semibold text-[#111827]">
                                {form && form.id ? 'Edit' : 'Add'} Booking System
                            </h3>
                            <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Booking Syst</p>
                        </div>
                    </div>

                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6">
                            <label>Name<span className="star">*</span></label>
                            <input
                                type="text"
                                className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey"
                                value={form.name}
                                onChange={e => setform({ ...form, name: e.target.value })}
                                required
                            />
                        </div>

                    

                        {/* <div className="col-span-12 md:col-span-12">
                            <label>Name<span className="star">*</span></label>
                            <SelectDropdown
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Select Booking System"
                                intialValue={form.name}
                                result={e => {setform({...form,name:e.value})}}
                                options={bookingSystemModel.list}
                            />
                             {submitted && !form.name?<div className="text-danger">Name is Required</div>:<></>}
                        </div> */}
                        {/* <div className="col-span-12 md:col-span-12">
                            <label>Api Key<span className="star">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                value={form.apiKey}
                                disabled={id?true:false}
                                onChange={e => setform({ ...form, apiKey: e.target.value })}
                                required
                            />
                        </div>
                        <div className="col-span-12 md:col-span-12">
                            <label>Secret Key<span className="star">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                value={form.secretKey}
                                disabled={id?true:false}
                                onChange={e => setform({ ...form, secretKey: e.target.value })}
                                required
                            />
                        </div> */}
                        <div className="col-span-12 md:col-span-6">
                            <label className='lablefontcls'>Logo</label><br></br>
                            <ImageUpload model="users" label="Upload Logo" result={e => imageResult(e, 'logo')} value={images.logo || form.logo} multiple={false} />
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <label className='lablefontcls'>Visible to All?</label><br></br>
                            <div class="col-span-12 md:col-span-8 mb-3">
                                <div className="check_checklist">
                                    <label class="form-check form-check-inline check_inline">
                                        <input class="form-check-input" type="radio" value="yes" checked={form.visible_to_all ? true : false} onChange={e => setform({ ...form, visible_to_all: true,domains:[] })} name="applyRateForAll" />
                                        <span class="form-check-label">Yes</span>
                                    </label>
                                    <label class="form-check form-check-inline check_inline">
                                        <input class="form-check-input" type="radio" value="no" checked={!form.visible_to_all ? true : false} onChange={e => setform({ ...form, visible_to_all: false,domains:[''] })} name="applyRateForAll" />
                                        <span class="form-check-label">No</span>
                                    </label>

                                </div>
                            </div>
                        </div>

                        {
                            !form?.visible_to_all ?<>
                            <div></div>
                                {/* <label>Domains<span className="star">*</span></label> */}

                                {form?.domains?.map((item,index)=>( <div className="col-span-12 flex md:col-span-6">
                                    <input
                                        type="text"
                                        placeholder="Enter Domain here "
                                        className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey"
                                        value={item}
                                        onChange={e => Domainshandler(index,e.target.value)}
                                        required
                                    />
                                    {/* {index == 0 ? null : */}
                                     <Tooltip placement="top" title="Delete">
                                        <span
                                            className="border ml-1 cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl mr-1"
                                            onClick={(e) => HandleAddOrDeleter(e,'delete',index)}
                                        >
                                            <BsTrash3 />
                                        </span>
                                    </Tooltip>
                                    {/* } */}
                                </div>))}
                                <div className="text-left col-span-12 md:col-span-6">
                                    <i class="material-icons sidenv addIc bg-primary" title="Add More" onClick={e => HandleAddOrDeleter(e)}>add</i>
                                </div>
                            </>:null
                        }

                        <div className="col-span-12 md:col-span-12">
                            <label className='lablefontcls'>Instructions</label><br></br>
                            <Editor
                                textareaName="content"
                                value={form?.instructions}
                                className="tuncketcls"
                                onEditorChange={(newValue, editor) => {
                                    setform({ ...form, instructions: newValue });
                                }}
                                apiKey={environment.tinyapiKey}
                                // apiKey="umvdq7vkiacu5ldyurqgqxct553ehzvebsrsccd42pna9x99"
                                init={{
                                    selector: "textarea#local-upload",
                                    height: 250,
                                    plugins: 'lists advlist | image | link',
                                 
                                    toolbar: 'formatselect | bold italic underline strikethrough | bullist numlist outdent indent | image  | link',
                                    menubar: false, // Hide the default menubar
                                    advlist_bullet_styles: 'square, circle, disc', 
                                    automatic_uploads: true,
                                    /*
                                  
                                      here we add custom filepicker only to Image dialog
                                    */
                                    file_picker_types: 'image',
                                    /* and here's our custom image picker*/
                                    file_picker_callback: function (cb, value, meta) {
                                        var input = document.createElement('input');
                                        input.setAttribute('type', 'file');
                                        input.setAttribute('accept', 'image/*');

                                        /*
                                          Note: In modern browsers input[type="file"] is functional without
                                          even adding it to the DOM, but that might not be the case in some older
                                          or quirky browsers like IE, so you might want to add it to the DOM
                                          just in case, and visually hide it. And do not forget do remove it
                                          once you do not need it anymore.
                                        */

                                        input.onchange = function () {
                                            var file = this.files[0];

                                            var reader = new FileReader();
                                            reader.onload = function () {
                                                /*
                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                  registry. In the next release this part hopefully won't be
                                                  necessary, as we are looking to handle it internally.
                                                */
                                                var id = 'blobid' + (new Date()).getTime();
                                                var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                                var base64 = reader.result.split(',')[1];
                                                var blobInfo = blobCache.create(id, file, base64);
                                                blobCache.add(blobInfo);

                                                /* call the callback and populate the Title field with the file name */
                                                cb(blobInfo.blobUri(), { title: file.name });
                                            };
                                            reader.readAsDataURL(file);
                                        };

                                        input.click();
                                    },
                                }}
                                required
                            />
                            <br/>
                            {submitted&&!form?.instructions?<span className="text-red-600 ml-3">Instructions are required</span>:null}
                        </div>
                    </div>
                    
                    <div className="flex justify-end">

                        <button type="submit" className="!px-4 text-sm font-medium text-primary hover:text-white hover:no-underline h-10 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">Save</button>
                    </div>
                </div>


            </form>
        </Layout>
    </>
}

export default AddEditBookingSystem