import React, { useState, useEffect, useRef } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from 'react-redux';
import methodModel from "../../methods/methods";
import statusModel from "../../models/status.model";
import { couponType } from "../../models/type.model";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import SelectDropdown from "../../components/common/SelectDropdown";
import datepipeModel from "../../models/datepipemodel";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker, Form, Space } from 'antd';
import ReactDatePicker from "react-datepicker";
const { RangePicker } = DatePicker;
import { Tooltip } from "antd";
import { LeadsType } from "../../models/type.model";
import GooglePlaceAutoComplete from "../../components/common/GooglePlaceAutoComplete";
import addressModel from "../../models/address.model";
import { FaSpinner } from "react-icons/fa";
import { BsCheckCircleFill, BsTrash3 } from "react-icons/bs";
import { LuMail } from "react-icons/lu";
import ImageUpload from "../../components/common/ImageUpload";
import PhoneInput from "react-phone-input-2";
import { CountryCodeFinder } from "../../components/common/CountryFile/CountryCodeList";
import { FiEdit3 } from "react-icons/fi";
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'


const AddEditLeads = () => {
    const calendorref = useRef();
    const searchState = useSelector((state) => state.search);
    const [blur, setblur] = useState(false)
    const defaultvalue = () => {
        let keys = { ...LeadsType }
        Object.keys(LeadsType).map(itm => {
            keys[itm] = ''
        })
        keys.status = 'active'
        return keys
    }
    const [LeadNotes,setLeadNotes]=useState([]);
    const { id } = useParams()
    const minDate = new Date()
    const [form, setform] = useState(defaultvalue())
    const [loaging, setLoader] = useState(false)
    const [categories, setCategories] = useState(false)
    const [category, setCategory] = useState([])
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const formValidation = [
        { key: 'status', required: true },
        { key: 'discountType', required: true },
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if(!AddressSellected){return}
        let invalid = methodModel.getFormError(formValidation, form)

        // if (form.discountType == 'Percentage' && Number(form.discountAmount) > 100) {
        //     toast.error("Discount Amount Maximum value is 100")
        //     return
        // }

        // if (invalid) return
        if(!form?.logo){return}
        let method = 'post'
        let url = 'api/lead'
        let value = {
            ...form
        }
        if (value.id) {
            method = 'put'
            url = 'api/lead/update'
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                history("/leads")
            }
            loader(false)
        })
    }

 

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get('api/lead/detail', { id }).then(res => {
                if (res.success) {
                    let value = res.data 
                    let payload = couponType
                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })
                    setAddressSellected(true)
let resultUpload=value?.logo.includes('https')?true:false;
                    setform({
                        ...value,
                        uploadLogo:resultUpload
                    });
                    setblur(true)
                }
                loader(false)
            })

            GetNotesDetail()
        } 
    }, [id])

    useEffect(()=>{
        if(id){
GetNotesDetail({search:searchState})
        }
    },[searchState])
    const GetNotesDetail=(p={})=>{
        loader(true)
        const filter = { leadId:id,...p}
        ApiClient.get(`api/lead-note/listing`,filter).then(res => {
            if (res.success) {
                setLeadNotes(res.data)
            }
            loader(false)
        })
    }

    const isExist = (p) => {
        let value = false
        if (category.find(itm => itm == p)) {
            value = true
        }
        return value
    }

    const setcheckbox = (arr = []) => {
        let els = document.querySelectorAll("input.categoryCheckbox")
        els.forEach(el => {
            if (arr.find(itm => itm == el.id)) {
                el.checked = true
            } else {
                el.checked = false
            }
        })
    }

    const checkbox = (itm) => {
        let arr = category
        let exist = isExist(itm.id)
        if (exist) {
            arr = arr.filter(aitm => aitm != itm.id)
        } else {
            arr.push(itm.id)
        }
        setcheckbox(arr)
        setCategory(arr)
    }

    const toggle = (pi, si = -1, ssi = -1) => {
        let arr = categories
        if (ssi >= 0) {
            arr[pi].childCategories[si].childCategories[ssi].active = true
        } else if (si >= 0) {
            arr[pi].childCategories[si].active = true
        } else if (pi >= 0) {
            arr[pi].active = true
        }
        setCategories(p => p = arr)
    }

    const imageResult = (e, key) => {
        setform({...form,logo:e.value})
    }

    const TableRow = ({ itm, className, pi = -1, si = -1, ssi = -1 }) => {
        return <tr className={`data_row ${className}`}>
            <td className="checkboxTd" onClick={e => checkbox(itm)}>
                <input type="checkbox" className="categoryCheckbox" disabled id={`${itm.id}`}
                //  checked={isExist(itm.id)}
                />
                {itm.checked}
            </td>
            <td className='table_dats'> <div className='user_detail' onClick={e => checkbox(itm)}>
                <img src={methodModel.userImg(itm.banner)} className="user_imgs" />
                <div className='user_name'>
                    <h4 className='user'>
                        {itm.name}
                    </h4>
                </div>
            </div></td>
            <td className="text-right">
                <i className={`fa ${itm.active ? 'fa-chevron-up' : 'fa-chevron-down'}`} onClick={e => toggle(pi, si, ssi)}></i>
            </td>
        </tr>
    }

    //  For Dedtapicker 
    const dateconvert = (date) => {

        const startdatedate = date[0];
        const enddate = date[1];
        const convertedstart = datepipeModel.datetostring(startdatedate);
        const convertedend = datepipeModel.datetostring(enddate);
        setform({ ...form, dateFrom: convertedstart, dateTo: convertedend });
        // alert(convertedstart)
        // alert(convertedend)
    }

    const [TimezoneLoader, setTimezoneLoader]=useState(false);
    const [AddressSellected, setAddressSellected]=useState(false)

    const addressResult = async (e) => {
        let address = {}
        if (e.place) {
            address = addressModel.getAddress(e.place)
            setAddressSellected(true)
        } else {
            setAddressSellected(false)
        }
        setform({
            ...form,
            
            address: e.value,
            country: address.country || '',
            city: address.city || '',
            state: address.state || '',
            zipcode: address.zipcode || '',
            lat: address.lat || '',
            lng: address.lng || ''
        })
        if (e.place) {
            setTimezoneLoader(true)
            const apires = await addressModel.gettimeZone(e.place)
            setTimezoneLoader(false)

            const code = CountryCodeFinder(address.country,'000999')
            setform({
                ...form,
                address: e.value,
                mobileNo: code,
                countryCode: address.countryCode || '',
                country: address.country || '',
                city: address.city || '',
                state: address.state || '',
                pinCode: address.zipcode || '',
                lat: address.lat || '',
                lng: address.lng || '',
                timezone: apires?.data?.timeZoneId || ''
            })
        }
    }

    const [NotesForm, setNotesForm] = useState({
        "title": "",
        "note": "",
    })
    const [rating, setRating] = useState(0);
    const [hoveredRating, setHoveredRating] = useState(0);

    const handleMouseEnter = (value) => {
        setHoveredRating(value);
    };

    const handleMouseLeave = () => {
        setHoveredRating(0);
    };

    const handleClick = (value) => {
        setRating(value);
    };

    const [NoteFormSubmit,setNoteFormSubmit]=useState(false)
    const HandleNoteFormSubmit=(e)=>{
        e.preventDefault()
        setNoteFormSubmit(true)
        if(rating==0){
            return
        }
        const payload={ 
                "title": NotesForm.title,
                "note": NotesForm.note,
                "confidence_level": rating,
                leadId:id
        }
        let method='post';
        let url ='api/lead-note'
        if(NotesForm.id){
            url ='api/lead-note/update'
            method='put'
            payload['id']=NotesForm.id
        }else{

        }
        loader(true)
        ApiClient.allApi(url,payload,method).then(res=>{
            if(res.success){
                document.getElementById('closeNoteModal').click()
GetNotesDetail()
            }
            loader(false)
        })
    }

    const NoteDelete=(DeleteId)=>{
        if(window.confirm('Do you want to Delete this Note?')){
        loader(true);
        ApiClient.delete(`api/lead-note/delete`,{id:DeleteId}).then(res=>{
            if(res.success){
                GetNotesDetail()
            }
            loader(false)
        })
    }

    }
    const edit=(id)=>{
        loader(true);
        ApiClient.get(`api/lead-note/detail`,{id:id}).then(res=>{
            if(res.success){
            setNotesForm({...res.data})
            setRating(res.data?.confidence_level)
            }
            loader(false)
        })
    }


    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
      setIsOpen(false)
    }
  
    function openModal() {
      setIsOpen(true)
    }



    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="p-6 shadow-box border !border-grey  rounded-large bg-white ">

                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <Link to="/leads" className="!px-4  py-2 flex items-center justify-center  rounded-full shadow-btn hover:bg-[#F3F2F5] border border-grey  transition-all    mr-3 h-12 w-12"><i className='fa fa-angle-left text-lg'></i></Link>
                        </Tooltip>
                        <div>
                            <h3 className="text-2xl font-semibold text-[#111827]">
                                {form && form.id ? 'Edit' : 'Add'} Lead
                            </h3>
                            <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Leads</p>
                        </div>
                    </div>


                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6">
                            <label>Business Name<span className="star">*</span></label>
                            <input
                                type="text"
                                className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey"
                                value={form.name}
                                onChange={e => setform({ ...form, name: e.target.value })}
                                required
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Trading Name<span className="star">*</span></label>
                            <input
                                type="text"
                                className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey"
                                value={form.trading_name}
                                onChange={e => setform({ ...form, trading_name: e.target.value })}
                                required
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Logo<span className="star">*</span></label>
                            <div className="col-span-8">
                                <div className="flex flex-wrap items-center gap-3">
                                    <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !px-3 shadow-box !rounded-md">
                                        <input
                                            className="h-4 w-4 checked:!ring-primary checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary"
                                            type="radio"
                                            value="yes"
                                            checked={form.uploadLogo ? true : false}
                                            onChange={(e) =>
                                                setform({ ...form, uploadLogo: true,logo:'' })
                                            }
                                            name="showAddress"
                                        />
                                        Enter URL
                                    </label>
                                    <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !px-3 shadow-box !rounded-md">
                                        <input
                                            className="h-4 w-4 checked:!ring-primary checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary"
                                            type="radio"
                                            value="no"
                                            checked={!form.uploadLogo ? true : false}
                                            onChange={(e) =>
                                                setform({ ...form, uploadLogo: false })
                                            }
                                            name="showAddress"
                                        />
                                        Upload
                                    </label>
                                </div>
                                {form.uploadLogo ? (
                                    <>
                                        <div className="relative mt-[18px]">
                                            <input
                                                type="text"
                                                className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey"
                                                value={form?.logo}
                                                onChange={e => setform({ ...form, logo: e.target.value })}
                                                required
                                            />
                                            
                                            <BsCheckCircleFill className="text-[#481196] text-lg opacity-30 absolute top-[11px] right-3.5" />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                            <div className="col-span-12 md:col-span-6 mt-2">
                                                <ImageUpload model="users" result={e => imageResult(e, 'banner')} value={form?.logo} />
                                            </div>
                                    </>
                                )}
                                {submitted&&!form?.logo?<p className="text-red-600">Logo is required</p>:null}
                            </div>
                            
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Address<span className="star">*</span></label>
                            <GooglePlaceAutoComplete
                                value={form.address}
                                result={addressResult}
                                id="address"
                                placeholder="Type address"
                            />

                            {submitted&&!AddressSellected?<span className="text-red-600">Address is Required</span>:null}
                        
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <label>TimeZone  {TimezoneLoader && (
                                <FaSpinner className="animate-spin inline-block" />
                            )}<span className="star">*</span></label>
                            <input
                                type="text"
                                disabled
                                className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey"
                                value={form?.timezone}
                                onChange={e => setform({ ...form, timezone: e.target.value })}
                                required
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <label>Mobile Number<span className="star">*</span></label>
                            <div className="mobile_number">
                            <PhoneInput
                                className=""
                                value={form.mobileNo}
                                enableSearch={true}
                                inputStyle={{ width:"448px"}}
                                limitMaxLength
                                placeholder=""
                                required
                                onChange={e => setform({ ...form, mobileNo: e })}
                                countryCodeEditable={true}
                            />
                            </div>
                            </div>

                        <div className="col-span-12 md:col-span-6">
                            <label>Email<span className="star">*</span></label>
                            <input
                                type="email"
                                className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey"
                                value={form?.email}
                                onChange={e => setform({ ...form, email: e.target.value })}
                                required
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Website Address<span className="star">*</span></label>
                            <input
                                type="url"
                                className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey"
                                value={form?.website}
                                onChange={e => setform({ ...form, website: e.target.value })}
                                required
                            />
                        </div>
                
                        <div className="col-span-12 md:col-span-6">
                            <label>Status<span className="star">*</span></label>
                            <div className="custom_dropdown">
                                <SelectDropdown
                                    isSingle={true}
                                    id="statusDropdown"
                                    displayValue="name"
                                    intialValue={form.status}
                                    result={e => { setform({ ...form, status: e.value }) }}
                                    options={statusModel.list2}
                                />
                                {submitted && !form.status ? <div className="text-danger">Status is Required</div> : <></>}
                            </div>
                        </div>

            
             

                
               
                    
    
        
               
               
  
                    </div>

                    <div className="flex justify-end mt-6">

                        <button type="submit" className="!px-4 text-sm font-medium text-primary hover:text-white hover:no-underline h-10 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">Save</button>
                    </div>




                </div>


            </form>
            {id?<div className=" shadow-box border pb-4 !border-grey  rounded-large bg-white mt-4">
            <div   className="flex justify-end p-4" onClick={e=>{setNotesForm({note:'',title:''});setNoteFormSubmit(false); setRating(0);openModal()}} >
                    <div className="bg-primary leading-10  h-10 items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg cursor-pointer">
                    Add Note
                    </div>
            </div>

                <div className=" w-full bg-white rounded-lg">
                    <div className="scrollbar w-full overflow-auto">


                        <table class="w-full">
                            <thead className='border-b border-[#EAECF0]'>
                                <tr className='border-b border-[#EAECF0]'>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' >Date
                                        {/* <span className='ml-2><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span> */}
                                    </th>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 '>Note Title 
                                    {/* <span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span> */}
                                    </th>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' >Confidence Level
                                        {/* <span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span> */}
                                    </th>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' >Created At
                                        {/* <span className='ml-2><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span> */}
                                    </th>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 '>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {LeadNotes.map((itm, i) => {
                                    return <tr className=''>
                                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]' onClick={e => edit(itm.id)}>
                                            <h4 className='user'>
                                                {datepipeModel.date(itm.createdAt)}
                                            </h4></td>
                                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]' onClick={e => edit(itm.id)}>
                                                    <h4 className='user'>
                                                        {itm.title}
                                                    </h4></td>
                                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>
                                            <div className="flex items-center">
                                                {[...new Array(5)].map((value,index) => (
                                                    <button
                                                        key={value}
                                                        className={`text-3xl ${index < itm?.confidence_level ? 'text-yellow-400' : ''} focus:outline-none ${index < itm?.confidence_level ? 'fill-current' : 'fill-none'
                                                            }`}

                                                    >
                                                        ★
                                                    </button>
                                                ))}
                                            </div>
                                            
                                            </td>
                                        
                                        {/* dropdown */}
                                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                                            <div className="flex items-center justify-center gap-1.5">

                                                <Tooltip placement="top" title="Edit">
                                                    <a className='border cursor-pointer border-primary hover:opacity-70 rounded-large bg-[#48119614] w-10 h-10 !text-primary flex items-center justify-center text-xl' title="Edit" data-toggle="modal" data-target="#categoryModal" onClick={e => edit(itm.id)}>
                                                        <FiEdit3 />
                                                    </a>
                                                </Tooltip>
                                                <>
                                                    <Tooltip placement="top" title="Delete">
                                                        <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl' onClick={() => NoteDelete(itm.id)}>
                                                            <BsTrash3 />
                                                        </span>
                                                    </Tooltip>
                                                </>
                                            </div>
                                        </td>

                                    </tr>

                                })
                                }
                            </tbody>
                        </table>



                    </div>
                </div>
                {id&&LeadNotes.length==0?<div className="text-center  pt-2">No Data </div>:null}
            </div>:null}
           
        </Layout>



        <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Note
                  </Dialog.Title>
                  <div className="mt-4 ">
                  <form onSubmit={HandleNoteFormSubmit}>
                        <div className=" mb-3">
                            <label>Title</label>
                            <input className="shadow-box border border-grey  bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary" value={NotesForm.title} onChange={e=>setNotesForm({...NotesForm,title:e.target.value})} required />
                        </div>
                        <div className=" mb-3">
                            <label>Notes</label>
                            <textarea className="shadow-box border border-grey  bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary" value={NotesForm.note} onChange={e => setNotesForm({ ...NotesForm, note: e.target.value })}  required rows={12}/>
                        </div>
                        <div className="">
<label>Confidence Level</label>
                        <div className="flex items-center">
                            {[1, 2, 3, 4, 5].map((value) => (
                                <button
                                type="button"
                                    key={value}
                                    className={`text-3xl  ${value <= (hoveredRating || rating) ? 'text-yellow-400' : ''} focus:outline-none ${value <= (hoveredRating || rating) ? 'fill-current' : 'fill-none'
                                        }`}
                                    onMouseEnter={() => handleMouseEnter(value)}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => handleClick(value)}
                                >
                                    ★
                                </button>
                            ))}
                        </div>
                        </div>
                        {NoteFormSubmit&&rating==0?<div className="text-red-400 text-center">Confidence Level is required</div>:null}
                            <div className="flex justify-end gap-2 mt-4">
                                <button type="button" className="bg-white border border-grey  px-4 py-2 rounded-large text-black"  onClick={closeModal}>Close</button>
                                <button type="submit" className="bg-primary px-4 py-2 rounded-large text-white">Save</button>
                            </div>
                        </form>
                  </div>

                
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>


        
    </>
}

export default AddEditLeads