import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from 'react-redux';
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import SelectDropdown from "../../components/common/SelectDropdown";
import { cityType } from "../../models/type.model";
import statusModel from "../../models/status.model";
import { Tooltip } from "antd";

const AddEditCity = () => {

    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const { id } = useParams()
    const user = useSelector((state) => state.user);
    const defaultvalue = () => {
        let keys = { ...cityType }
        Object.keys(cityType).map(itm => {
            keys[itm] = ''
        })
        keys.status = 'active'
        return keys
    }
    const [form, setform] = useState(defaultvalue())
    //   For Getting Continent data
    const [continentdata, setcontinentdata] = useState([]);
    const GetContinentData = () => {
        ApiClient.get(`api/continent/listing`, { status: 'active', sortBy: 'name asc' }).then(res => {
            if (res.success == true) {
                setcontinentdata(res.data);
            }
        })
    }
    //  For Getting the Country Data
    const [countrydata, setcountrydata] = useState([]);
    const GetCountryData = (p = {}) => {
        ApiClient.get(`api/country/listing`, { status: 'active', sortBy: 'name asc', ...p }).then(res => {
            if (res.success == true) {
                setcountrydata(res.data)
            }
        })
    }
    //  Get the Region Data
    const [regiondata, setregiondata] = useState([])
    const GetRegionData = (p = {}) => {
        ApiClient.get(`api/region/listing`, { status: 'active', sortBy: 'name asc', ...p }).then(res => {
            if (res.success == true) {
                setregiondata(res.data)
            }
        })
    }
    useEffect(() => {
        GetContinentData();
        GetCountryData();
        // GetRegionData();
    }, [])

    const formValidation = [
        { key: 'status', required: true },
        { key: 'regionId', required: true },
        { key: 'countryId', required: true },
        { key: 'continent', required: true },
    ]
    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        let method = 'post'
        // URL
        let url = 'api/city'
        let value = {
            ...form
        }
        if (value.id) {
            url = 'api/city/update'
            method = 'put'
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                history("/cities")
            }
            loader(false)
        })
    }

    useEffect(() => {
        if (id) {
            loader(true)
            // API
            ApiClient.get('api/city/detail', { id }).then(res => {
                if (res.success) {
                    let value = res.data
                    let payload = defaultvalue()
                    let oarr = Object.keys(defaultvalue())
                    oarr.map(itm => {
                        payload[itm] = value[itm] || ''
                    })
                    payload.countryId = value.countryId?._id
                    payload.continent = value.continent?._id
                    payload.regionId = value.regionId?._id

                    setform(payload)
                    setSubmitted(false)
                }
                loader(false)
            })
        }
    }, [id])

    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">

                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <Link to="/cities" className="!px-4  py-2 flex items-center justify-center  rounded-full shadow-btn hover:bg-[#F3F2F5] border border-grey  transition-all    mr-3 h-12 w-12"><i className='fa fa-angle-left text-lg'></i></Link>
                        </Tooltip>
                        <div>
                            <h3 className="text-2xl font-semibold text-[#111827]">
                                {form && form.id ? 'Edit' : 'Add'} City
                            </h3>
                            <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  City</p>
                        </div>
                    </div>


                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6">
                            <label>Name<span className="star">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                value={form.name}
                                onChange={e => setform({ ...form, name: e.target.value })}
                                required
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Continent<span className="star">*</span></label>
                            <div className="custom_dropdown">
                                <SelectDropdown
                                    isSingle={true}
                                    id="statusDropdown"
                                    placeholder="Select Continent"
                                    displayValue="name"
                                    intialValue={form.continent}
                                    theme="search"
                                    result={e => {
                                        setform({ ...form, continent: e.value, regionId: '', countryId: '' })
                                        GetCountryData({ continentId: e.value })
                                    }}
                                    options={continentdata}
                                />
                                {submitted && !form.continent ? <div className="text-danger">Continent is Required</div> : <></>}
                            </div>
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <label>Country<span className="star">*</span></label>
                            <div className="custom_dropdown">
                                <SelectDropdown
                                    isSingle={true}
                                    id="statusDropdown"
                                    placeholder="Select Country"
                                    displayValue="name"
                                    intialValue={form.countryId}
                                    theme="search"
                                    result={e => {
                                        setform({ ...form, countryId: e.value, regionId: '' })
                                        GetRegionData({ countryId: e.value })
                                    }}
                                    options={countrydata}
                                />
                                {submitted && !form.countryId ? <div className="text-danger">Country is Required</div> : <></>}
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Region<span className="star">*</span></label>
                            <div className="custom_dropdown">
                                <SelectDropdown
                                    isSingle={true}
                                    id="statusDropdown"
                                    displayValue="name"
                                    placeholder="Select Region"
                                    intialValue={form.regionId}
                                    theme="search"
                                    result={e => { setform({ ...form, regionId: e.value }) }}
                                    options={regiondata}
                                />
                                {submitted && !form.regionId ? <div className="text-danger">Region is Required</div> : <></>}
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-3">
                            <label>Status<span className="star">*</span></label>
                            <div className="custom_dropdown">
                                <SelectDropdown
                                    placeholder="Select Status"
                                    id="statusDropdown"
                                    displayValue="name"
                                    intialValue={form.status}
                                    result={e => { setform({ ...form, status: e.value }) }}
                                    options={statusModel.list}
                                />
                                {submitted && !form.status ? <div className="text-danger">Status is Required</div> : <></>}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end mt-6">

                        <button type="submit" className="!px-4 text-sm font-medium text-primary hover:text-white hover:no-underline h-10 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">Save</button>
                    </div>
                </div>


            </form>
        </Layout>
    </>
}

export default AddEditCity