import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import methodModel from "../../../methods/methods";
import SelectDropdown from "../../../components/common/SelectDropdown";
import { earlybirdpricingType } from "../../../models/type.model";
import countModel from "../../../models/count.model";
import Layout from "../../../components/global/layout";
import './style.scss';
import Select from "react-select";
import DateRangePicker from "../../../components/common/DateRangePicker";
import datepipeModel from "../../../models/datepipemodel";
import { Tooltip } from "antd";
import { MdDeleteOutline, MdOutlineAdd } from "react-icons/md";

const AddEditEarlyBirdPricing = () => {
    const { id, type, copy } = useParams()
    const defaultvalue = () => {
        let keys = { ...earlybirdpricingType }
        Object.keys(earlybirdpricingType).map(itm => {
            if (itm != 'permissions') keys[itm] = ''
        })
        keys.status = 'active'
        return keys
    }

    const symbol = [
        { id: '<', name: '<' },
        { id: '>', name: '>' }
    ]

    const dummyArray = [
        { time: '0900 to 1000' },
        { time: '1000 to 1100' },
        { time: '1400 to 1500' },
        { time: '1500 to 1600' }
    ]
    const [form, setform] = useState(earlybirdpricingType)
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const [date, setdate] = useState([{ startDate: '', endDate: '' }])
    const [country, setcountry] = useState()

    const formValidation = [
        { key: 'name', required: true },
        // { key: 'changesDate', required: true },
        { key: 'notApplicableFor', required: true },
        { key: 'country', required: true },
        // { key: 'amountOrPercent', required: true },
        // { key: 'discOrPre', required: true },

    ]

    const applicables = ["State", "National"]

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        let method = 'post'
        let url = 'api/dynamic/pricing'
        let value = {
            ...form,
            type: 'earlyBirdCustomDates',
            blackOutDates: date,
            country: form.country || 'us',
        }
        if (value.id && copy == 'false') {
            method = 'put'
            url = 'api/dynamic/pricing/update'
        } else {
            delete value.id
        }
        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                history("/dynamicpricelist")
            }
            loader(false)
        })
    }

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get('api/dynamic/pricing/detail', { id }).then(res => {
                if (res.success) {
                    let value = res.data
                    let payload = earlybirdpricingType
                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })
                    payload.changesDate = payload.changesDate ? new Date(payload.changesDate) : ''
                    payload.changesDateTo = payload.changesDateTo ? new Date(payload.changesDateTo) : ''
                    if (payload.blackOutDates) {
                        setdate([...payload.blackOutDates.map(itm => {
                            return { startDate: itm.startDate ? new Date(itm.startDate) : '', endDate: itm.endDate ? new Date(itm.endDate) : '' }
                        })])
                    }
                    if (copy == 'true') {
                        payload.name = `Copy of ${payload.name}`
                    }
                    setform({
                        ...payload
                    })
                }
                loader(false)
            })
        } else {
            setform(defaultvalue())
        }
        getCountry()
    }, [id])

    const selectAll = (checked) => {
        if (checked) {
            setform({ ...form, notApplicableFor: [...applicables] });
        } else {
            setform({ ...form, notApplicableFor: [] });
        }

    }

    const setchecks = (value, checked) => {
        let applyFor = form.notApplicableFor || []
        if (checked == true) {
            applyFor.push(value)
        }
        else {
            applyFor = applyFor.filter(itm => itm != value)
        }
        setform({ ...form, notApplicableFor: applyFor })
    }

    const addmore = () => {
        setdate([...date, { blackOutDates: '' }])
    }
    const remove = (index) => {
        const rows = [...date];
        rows.splice(index, 1);
        setdate(rows);
    }

    const setalldates = (index, value, key) => {
        const field = [...date]
        let endDate = value
        if (key == 'startDate') endDate = value
        field[index] = { ...field[index], [key]: value, endDate: endDate }
        setdate([...field]);
    }

    const setalldays = (checked) => {
        if (checked) {
            setform({ ...form, daysToApply: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] });
        } else {
            setform({ ...form, daysToApply: [] });
        }
    }

    const setdays = (value, checked) => {
        let array = form.daysToApply || []
        if (checked == true) {
            array.push(value)
        }
        else {
            array = array.filter(item => item != value)
        }
        setform({ ...form, daysToApply: array })
    }

    const setTimeSlot = (data, checked) => {
        let timeslotarray = form.timeSlots || []
        if (checked == true) {
            timeslotarray.push(data)
        }
        else {
            timeslotarray = timeslotarray.filter(item => item.time != data.time)
        }
        setform({ ...form, timeSlots: timeslotarray })
    }

    const setAllTimeSlots = (checked) => {
        if (checked == true) {
            setform({ ...form, timeSlots: dummyArray.map(item => item) })
        }
        else {
            setform({ ...form, timeSlots: [] })
        }
    }

    const getCountry = () => {
        ApiClient.get(`api/holidays/countries`).then(res => {
            if (res.success) {
                let data = Object.keys(res.data).map(item => {
                    return ({ ...country, value: item.toLowerCase(), label: res.data[item] })
                })
                data = data.sort((a, b) => {
                    return a.name - b.name
                })
                setcountry(data)
            }
        })
    }

    const handlecountry = () => {
        let value = ''
        if (form.country) {
            value = country && country.find(item => item.value == form.country)
        }
        return value
    }

    const isAllNotApplicable = () => {
        let value = true
        if (form && !form.notApplicableFor) {
            return false
        }
        applicables.map(itm => {
            if (form && !form.notApplicableFor.find(titm => titm == itm)) {
                value = false
            }
        })
        return value
    }

    const blockDateChange = (e, index) => {
        const field = [...date]
        let startDate = e.startDate ? datepipeModel.datetostring(e.startDate) : null
        let endDate = e.endDate ? datepipeModel.datetostring(e.endDate) : null
        field[index] = { ...field[index], endDate, startDate }
        setdate([...field]);
    }

    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="p-6 shadow-box border !border-grey  rounded-large bg-white">

                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <Link to={`/dynamicpricelist`} className="!px-4  py-2 flex items-center justify-center  rounded-full shadow-btn hover:bg-[#F3F2F5] border border-grey  transition-all    mr-3 h-12 w-12"><i className='fa fa-angle-left text-lg'></i></Link>
                        </Tooltip>
                        <div>
                            <h3 className="text-2xl font-semibold text-[#111827]">
                                {form && form.id && copy == 'false' ? 'Edit' : 'Add'} Early Bird Pricing Custom Dates
                            </h3>
                        </div>
                    </div>




                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6">
                            <label>Rule Name<span className="star">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder='Early Bird Pricing - Custom Dates'
                                value={form.name}
                                onChange={e => setform({ ...form, name: e.target.value })}
                                required
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Country<span className="star">*</span></label>
                            <Select
                                options={country}
                                placeholder="Select Country"
                                isClearable={true}
                                value={handlecountry()}
                                onChange={e => setform({ ...form, country: e ? e.value : '' })}
                                className="text-gray-700 block text-sm options_classs"
                            />
                            {submitted && !form?.country ? <div className="text-danger">Country is Required</div> : <></>}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Select date to & from when the rates need to be applied<span className="star">*</span></label>
                            <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-12 md:col-span-6">
                                    <DatePicker
                                        className="form-control"
                                        selected={form.changesDate}
                                        minDate={new Date()}
                                        placeholderText="Start Date"
                                        name="changesDate"
                                        onChange={(date) => { setform({ ...form, changesDate: date, changesDateTo: '' }) }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                                <div className="col-span-12 md:col-span-6">
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="End Date"
                                        selected={form.changesDateTo}
                                        minDate={form.changesDate || new Date()}
                                        onChange={(date) => { setform({ ...form, changesDateTo: date }) }}
                                        name="changesDateTo"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                            </div>
                            {/* {submitted && (!form?.changesDate || !form?.changesDateTo) ? <div className="text-danger">Date Range is Required</div> : <></>} */}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Apply early bird pricing if the number of available spaces is<span className="star">*</span></label>
                            <span className="flex">
                                <SelectDropdown
                                    id="statusDropdown"
                                    displayValue="name"
                                    placeholder="Select One"
                                    intialValue={form.notApplyCondition}
                                    result={e => { setform({ ...form, notApplyCondition: e.value }) }}
                                    options={symbol}
                                />
                                <input
                                    type="text"
                                    className="form-control w-80 ml-3"
                                    placeholder="Number"
                                    minLength={1}
                                    maxLength={10}
                                    value={form.notApply}
                                    onChange={e => setform({ ...form, notApply: methodModel.isNumber(e) })}
                                />
                            </span>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Do not apply early bird pricing<span className="star">*</span></label>
                            <div class="checkPrice flex">
                                <label className="bg-white border border-gray-300 px-4 py-2 rounded-large cursor-pointer">
                                    <input type="checkbox" onChange={e => selectAll(e.target.checked)} checked={isAllNotApplicable()} className="mr-1" />
                                    All
                                </label>
                                {applicables.map(itm => {
                                    return <label className="bg-white border border-gray-300 px-4 py-2 rounded-large cursor-pointer">
                                        <input type="checkbox" className="mr-1" checked={form.notApplicableFor.includes(itm)} onChange={e => setchecks(itm, e.target.checked)} />
                                        {itm}
                                    </label>
                                })}
                            </div>
                            {submitted && !form.notApplicableFor ? <div className="text-danger">Do not apply early is Required</div> : <></>}
                        </div>
                        <div className="col-span-12 md:col-span-12">
                            <div className="border border-grey rounded-large p-4">

                          
                            <div className="flex items-center justify-between mb-4">
                                <label className="m-0">Add Block out Dates or Date Range </label>
                                <div className="" onClick={e => addmore()}>
                                    <p title="Add More" className="!px-4 text-sm font-medium text-primary hover:text-white hover:no-underline h-10 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">  <MdOutlineAdd className="text-md" />Add More</p>


                                </div>
                            </div>
                            <div className="grid grid-cols-12 gap-4">

                                {date && date.map((item, index) => {
                                    return <div className="col-span-12 md:col-span-12 ">
                                        <div className="flex items-center gap-2">
                                            <div className="calenderleft flex-grow">
                                                <DateRangePicker
                                                    value={item}
                                                    disabledDate={true}
                                                    id={index}
                                                    onChange={e => blockDateChange(e, index)}
                                                />


                                            </div>
                                            {index > 0 ?
                                                <>
                                                    <p onClick={e => remove(index)} className="!px-3 text-sm font-normal text-white hover:!text-white hover:!no-underline h-10 flex items-center justify-center gap-2 !bg-red-500 rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] cursor-pointer"><MdDeleteOutline className="text-xl" /></p>

                                                </>


                                                : null}
                                        </div>
                                    </div>
                                })}

                            </div>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Would you like to Apply Discount (-) or Add Premium (+)<span className="star">*</span></label>
                            <SelectDropdown
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Discount (-) Or Add Premium (+)"
                                intialValue={form.discOrPre}
                                result={e => { setform({ ...form, discOrPre: e.value }) }}
                                options={[
                                    { name: 'Discount', id: 'Discount' },
                                    { name: 'Premium', id: 'Premium' }
                                ]}
                            />
                            {/* {submitted && !form?.discOrPre ? <div className="text-danger">Discount (-) or Add Premium (+) is Required</div> : <></>} */}
                        </div>
                        <div className="col-span-12 md:col-span-4">
                            <label>Apply % or Amount<span className="star">*</span></label>
                            <SelectDropdown
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="% or Amount"
                                intialValue={form.amountOrPercent}
                                result={e => { setform({ ...form, amountOrPercent: e.value }) }}
                                options={countModel.list}
                            />
                            {/* {submitted && !form.amountOrPercent ? <div className="text-danger">Amount is Required</div> : <></>} */}
                        </div>
                        <div className="col-span-12 md:col-span-4">
                            <label>Add Number(please add number only not $ or % sign)<span className="star">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder=''
                                minLength={1}
                                maxLength={10}
                                value={form.number}
                                onChange={e => setform({ ...form, number: methodModel.isNumber(e) })}
                            />
                        </div>
                        
                    </div>
                    <div className="flex justify-end mt-6">

                        <button type="submit" className="!px-4 text-sm font-medium text-primary hover:text-white hover:no-underline h-10 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">Save</button>
                    </div>
                </div>
            </form>
        </Layout>
    </>
}

export default AddEditEarlyBirdPricing  