import React, { useEffect, useState } from 'react'
import Layout from '../../components/global/layout'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom';
import SelectDropdown from '../../components/common/SelectDropdown';
import GooglePlaceAutoComplete from "../../components/common/GooglePlaceAutoComplete";
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';
import { toast } from 'react-toastify';
import methodModel from '../../methods/methods';
import { resellerType } from '../../models/type.model';
import Select from "react-select";
import PhoneInput from 'react-phone-input-2';
import { Tooltip } from "antd";
import { FiPlus } from 'react-icons/fi';


export default function AddEditReseller() {
  const { id } = useParams();
  const Navigate = useNavigate();
  const [submit, setsubmit] = useState(false);
  const defaultValue = () => {
    let value = resellerType
    Object.keys(value).map(itm => {
      value[itm] = ''
    })
    return value
  }
  const [form, setform] = useState({ ...defaultValue() });
  const [Categories, setCategories] = useState([]);
  const [countries, setContries] = useState([]);
  const [moreContacts, setMoreContacts] = useState([]);
  //  For Handle Submit the Resller Data
  const HandleSubmit = (e) => {
    e.preventDefault();
    setsubmit(true);
    let payload = resellerType
    Object.keys(payload).map(itm => {
      payload[itm] = form[itm]
    })
    payload.id = id
    payload.moreContacts = moreContacts
    let method = "post";
    let url = "api/reseller";



    if (id) { method = "put"; url = `api/reseller/update` } else { delete payload.id }

    loader(true);
    ApiClient.allApi(url, payload, method).then(response => {
      if (response.success) {
        Navigate("/reseller");
        // toast.success(`Reseller ${method == "post" ? "Added" : "Updated"} Successfully`)
      }

      loader(false);
    })

  }
  const GetCountries = () => {
    ApiClient.get(`api/country/listing`, { status: 'active' }).then(res => {
      if (res.success) {
        setContries(res.data.map(itm => {
          return {
            label: itm.name,
            value: itm.id
          }
        }))
      }
    })
  }

  const GetCategories = () => {
    ApiClient.get(`api/categories/listing?catType=64b23b7d690d1d5f7ae76102&sortBy=order%20asc&status=active`).then(res => {
      let newarray = [];
      res.data.map((item, index) => {
        newarray.push({ id: item.id, name: item.name })
      })
      setCategories(newarray)
    })
  }

  //  For Getting the Data if user want to edit Reseller
  const GetEditData = () => {
    loader(true);
    ApiClient.get(`api/reseller/detail?id=${id}`).then(res => {
      if (res.success) {
        const data = res.data
        let payload = data
        if (payload.address) data.address = data.address
        if (payload.country) data.country = data.country?._id
        if (payload.category) data.category = data.category?._id

        if (data.moreContacts) {
          setMoreContacts(data.moreContacts)
        }
        setform({ ...payload })
      }
      loader(false);
    })
  }
  useEffect(() => {
    GetCategories()
    GetCountries()
    if (id) {
      GetEditData()
    }
  }, []);

  const handlecountry = () => {
    let value = ''
    if (form.country) {
      value = countries && countries.find(item => item.value == form.country)
    }
    return value
  }

  const uploadImage = (e) => {
    try {
      let files = e.target.files
      let file = files.item(0)
      if (!file) return
      loader(true)
      ApiClient.postFormData('api/upload/image?modelName=users', { file: file, modelName: 'users' }).then(res => {
        if (res?.fileName && res?.fileName != "") {
          let image = res.fileName
          setform({ ...form, logo: image })
        } else {
          setform({ ...form, logo: '' })
        }
        loader(false)
      })
    } catch {
      console.log('error');
    }
  }

  const addressResult = (e) => {
    setform({
      ...form,
      address: e.value
    })
  }

  const addContactPerson = () => {
    let arr = moreContacts
    arr.push({
      contactPerson: '',
      contactPhone: '',
      contactEmail: ''
    })
    setMoreContacts([...arr])
  }

  const removeContact = (index) => {
    let arr = moreContacts
    arr = arr.filter((itm, i) => i != index)
    setMoreContacts([...arr])
  }

  const editContact = (index, key, value) => {
    let arr = moreContacts
    arr[index][key] = value
    setMoreContacts([...arr])
  }

  return (
    <div>
      <Layout>


        <div className='bg-white shadow-box rounded-large w-full border border-grey p-4'>
          <div className='flex items-center mb-8'>
            <Tooltip placement="top" title="Back">
              <Link to="/reseller" className="!px-4  py-2 flex items-center justify-center  rounded-full shadow-btn hover:bg-[#F3F2F5] border border-grey  transition-all    mr-3 h-12 w-12"><i className='fa fa-angle-left text-lg'></i></Link>
            </Tooltip>
            <div>
              <h3 className="text-2xl font-semibold text-[#111827]">
                {id ? 'Edit' : 'Add'} Reseller
              </h3>
              <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Reseller</p>
            </div>
          </div>

          <div>
            <form onSubmit={e => HandleSubmit(e)} name="resellerForm">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-6">
                  <label>Name<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    name='name'
                    value={form.name}
                    onChange={e => setform({ ...form, name: e.target.value })}
                    required
                  />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <label>Contact Person</label>
                  <input
                    type="text"
                    className="form-control"
                    name='contactPerson'
                    value={form.contactPerson}
                    onChange={e => setform({ ...form, contactPerson: e.target.value })}
                  />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <label>Contact Email<span className="text-danger">*</span></label>
                  <input
                    type="email"
                    className="form-control"
                    name='contactEmail'
                    value={form.contactEmail}
                    onChange={e => setform({ ...form, contactEmail: e.target.value })}
                    required
                  />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <label>Contact Phone</label>
                  <div className="mobile_number">
                    <PhoneInput
                      className="col-span-12"
                      value={form.contactPhone ? form.contactPhone : ''}
                      enableSearch={true}
                      limitMaxLength
                      required
                      onChange={e => setform({ ...form, contactPhone: e })}
                      countryCodeEditable={true}
                    />
                  </div>
                  {/* <input
                  type="text"
                  className="form-control"
                  name='contactPhone'
                  value={form.contactPhone}
                  maxLength="10"
                  onChange={e => setform({ ...form, contactPhone: methodModel.isNumber(e) })}
                  required
                /> */}
                </div>
                <div className="col-span-12 md:col-span-6">
                  <label>Reseller Type</label>
                  <SelectDropdown
                    isSingle={true}
                    id="statusDropdown"
                    displayValue="name"
                    name='category'
                    placeholder="Select Reseller Type"
                    intialValue={form.category}
                    result={e => { setform({ ...form, category: e.value }) }}
                    options={Categories}
                  />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <label>Address</label>
                  {/* <GooglePlaceAutoComplete
                    value={form.address}
                    result={addressResult}
                    id="address"
                    placeholder=""
                  /> */}
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    value={form.address}
                    onChange={e => setform({ ...form, address: e.target.value })}
                    required
                  />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <label>Country</label>
                  <Select
                    options={countries}
                    placeholder="Select Country"
                    isClearable={true}
                    name="country"
                    value={handlecountry()}
                    onChange={e => setform({ ...form, country: e ? e.value : '' })}
                  />
                  {/* {submit && !form.country ? <span className='text-danger' style={{ fontSize: "13px" }}>Country is Required</span> : null} */}
                </div>
                {/* <div className="col-span-12 md:col-span-6">
                <label>Contract Date<span className="text-danger">*</span></label>
                <DatePicker
                  className="form-control"
                  selected={form.contractDate?new Date(form.contractDate):''}
                  placeholderText="Contract Date"
                  name="contractDate"
                  required
                  onChange={(date) => { setform({ ...form, contractDate: datepipeModel.datetostring(date) }) }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div> */}

                {/* <div className="col-span-12 md:col-span-6">
                <label>Booking<span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  name='booking'
                  value={form.booking}
                  onChange={e => setform({ ...form, booking: e.target.value })}
                  required
                />
              </div> */}

                <div className="col-span-12 md:col-span-6">
                  <label>Website Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name='contactPerson'
                    value={form.website}
                    onChange={e => setform({ ...form, website: e.target.value })}
                  />
                </div>

                <div className='maininutcls col-span-12 md:col-span-6 mb-3'>
                  <label className="profileImageLabel">
                    <img src={methodModel.userImg(form && form.logo)} className="profileImage" />
                  </label>
                  <div className='profile_btn'>
                    <div>
                      <label className="btn btn-primary edit ml-3">
                        <input
                          id="bannerImage"
                          type="file"
                          className="d-none"
                          accept="image/*"
                          onChange={(e) => { uploadImage(e); }}
                        />{form.image ? 'Change' : 'Upload'} Logo</label>
                    </div>
                    <div>
                      {form.logo ? <label className="btn btn-primary  delete ml-3" onClick={e => setform({ ...form, logo: "" })}>Remove Logo</label> : <></>}
                    </div>
                  </div>
                </div>

                <div className="col-span-12 md:col-span-12">
                  {/* {moreContacts.length ? <label>More Contacts<span className="text-danger">*</span></label> : <></>}


                  {moreContacts.map((itm, i) => {
                    return <div className='grid grid-cols-12 gap-4 shadow p-3 mx-0 mb-3'>
                      <div className="col-span-12 md:col-span-6">
                        <label>Contact Person<span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          name='contactPerson'
                          value={itm.contactPerson}
                          onChange={e => editContact(i, 'contactPerson', e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <label>Contact Phone<span className="text-danger">*</span></label>
                        <PhoneInput
                          className="col-md-3"
                          value={itm.contactPhone ? itm.contactPhone : ''}
                          enableSearch={true}
                          limitMaxLength
                          required
                          onChange={e => editContact(i, 'contactPhone', e)}
                          countryCodeEditable={true}
                        />
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <label>Contact Email<span className="text-danger">*</span></label>
                        <input
                          type="email"
                          className="form-control"
                          name='contactEmail'
                          value={itm.contactEmail}
                          onChange={e => editContact(i, 'contactEmail', e.target.value)}
                          required
                        />
                      </div>
                      <div className='col-span-12 md:col-span-6 text-right'>
                        <i className='fa fa-trash pointer' onClick={e => removeContact(i)} title="Remove"></i>
                      </div>
                    </div>
                  })} */}


                  {/* <div className='flex justify-end items-center mb-3'>
                    <button className='!px-3 text-sm font-normal hover:!text-white hover:no-underline text-white h-10 flex items-center justify-center gap-2 bg-primary rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed' type='button' onClick={e => addContactPerson()}> <FiPlus className="text-xl text-white" />Add Contact</button>
                  </div> */}
                </div>
              </div>
              <div className="flex justify-end mt-6">

                <button type="submit" className="!px-4 text-sm font-medium text-primary hover:text-white hover:no-underline h-10 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">Save</button>
              </div>

            </form>
          </div>



        </div>




      </Layout>
    </div>
  )
}
