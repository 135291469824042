import React from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link, useNavigate } from 'react-router-dom';
import userTableModel from '../../models/table.model';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import { Tooltip } from "antd";
import { HiOutlineArrowDown } from 'react-icons/hi';
import { FiEdit3, FiPlus } from 'react-icons/fi';
import { AiOutlineEye } from 'react-icons/ai';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import ApiClient from '../../methods/api/apiClient';
import { toast } from 'react-toastify';
import { IoMdSend } from "react-icons/io";
import { FaEye } from 'react-icons/fa';


const Html = ({
    sortClass,
    sorting,
    tab,
    edit,
    view,
    tabChange,
    colClick,
    ChangeRole,
    openModal,
    statusChange,
    pageChange,
    addCol,
    deleteItem,
    exportCsv,
    uTableCols,
    removeCol,
    filters,
    tableCols,
    loaging,
    data,
    types,
    isAllow,
    total
}) => {

const history=useNavigate()
const [SelectedEmail,setSelectedEmail]=useState('');
const EmailValidator = (email) => {
  if (email?.includes('.')) {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  } else {
    return false
  }

}


    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
      setIsOpen(false)
    }
  
    function openModal() {
      setIsOpen(true)
    }

    const [EnteredEmail,setEnteredEmail]=useState('');
    const [Submit,setSubmit]=useState(false)
    const HandlePreviewSender=(e)=>{
        e.preventDefault();
        setSubmit(true);
        if(!EmailValidator(EnteredEmail)){
            return
        }
        ApiClient.post(`api/send/template/preview`,{id:SelectedEmail,email:EnteredEmail}).then(res=>{
            if(res.success){
                setIsOpen(false);
                toast.success('Email sent successfully')
                setSelectedEmail('')
                setEnteredEmail('');
                setSubmit(false)
            }
        })
    }

    return (
        <Layout>
            <div className="flex justify-between mb-3">


                <div>
                    <h3 className="text-2xl font-semibold text-[#111827]">
                        Email Template
                    </h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Email Template</p>
                </div>



                <div className="flex">
                    {/* {isAllow('addEmailTemplate') ?
                        <Link className="bg-primary leading-10 mr-3 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-large gap-2" to="/emailtemplate/add">
                            <FiPlus className="text-xl text-white" /> Add Email
                        </Link>
                        : <></>
                    } */}
                    {/* <div className='icons_tab'>
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class={`nav-link ${tab == 'grid' ? 'active' : ''}`} id="employee-tab" onClick={e => tabChange('grid')}>
                                    <i className="fa fa-th"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class={`nav-link staff ${tab == 'list' ? 'active' : ''}`} id="staff-tab" onClick={e => tabChange('list')}>
                                    <i className="fa fa-list"></i>
                                </button>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
            {tab == 'grid' ? <>
                <div className="cardList">
                    <div className='grid grid-cols-12 gap-4'>
                        {!loaging && data && data.map((itm, i) => {
                            return <div className='col-span-12 md:col-span-6'>
                                <div className='new_cards'>
                                    <div className='user_card'>
                                        <div className='user_detail' onClick={e => edit(itm.id)}>
                                            <div className='user_name'>
                                                <h4 className='user'>
                                                    {itm.name}
                                                </h4>
                                                {/* <p className='user_info'>
                                                    {itm.email}
                                                </p> */}
                                            </div>
                                        </div>
                                        <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                            <span className='contract'>
                                                {itm.status == 'deactive' ? 'inactive' : 'active'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </> : <>
                <div className="shadow-box w-full bg-white rounded-lg mt-6 border overflow-hidden border-grey">
                    <div className="scrollbar w-full overflow-auto">


                        <table class="w-full">
                            <thead className='border-b border-[#EAECF0]'>
                                <tr className='border-b border-[#EAECF0]'>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' onClick={e => sorting('title')}>Title <span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 '>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loaging && data && data.map((itm, i) => {
                                    return <tr className=''>
                                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                                            <span className='' onClick={e => edit(itm.id)}>
                                                {itm.title}
                                            </span>
                                        </td>

                                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                                            <div className="flex items-center justify-start gap-1.5">
                                                {isAllow('editEmailTemplate') ?
                                                    <Tooltip placement="top" title="Edit">
                                                        <a className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                                        <FiEdit3     />
                                                        </a>
                                                    </Tooltip>
                                                    : <></>}
                                                {/* <span className='edit_icon' onClick={() => deleteItem(itm.id)}>
                                                    <i class="material-icons delete" title='Delete'> delete</i>
                                                </span> */}

<Tooltip placement="top" title="Send Preview">
                                                        <a  onClick={e=>{openModal();setSubmit(false); setSelectedEmail(itm?.id||itm?._id)}}  className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl" >
                                                        <IoMdSend />
                                                        </a>
                                                    </Tooltip>

                                                    <Tooltip placement="top" title="Preview">
                                                        <a className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl" onClick={e => history(`/emailtemplate/edit/${itm?.id}?selectTab=other`)}>
                                                        <FaEye     />
                                                        </a>
                                                    </Tooltip>
                                                
                                            </div>
                                        </td>
                                        {/* end */}
                                    </tr>
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>}
            {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
            {
                !loaging && total > filters.count ? <div className='paginationWrapper flex items-center justify-between mt-15'>
                    <span className='text-sm text-gray-600'>Show {filters.count} from {total} Email Template</span>
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }
            {loaging ? <div className="text-center py-4">
                <img src="/assets/img/loader.gif" className="pageLoader" />
            </div> : <></>}


            <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                   Send For Preview
                  </Dialog.Title>
                  <form onSubmit={e=>HandlePreviewSender(e)}>
                  <div className="mt-2">
                    <input type='email'  placeholder='Enter your email' value={EnteredEmail} onChange={e=>setEnteredEmail(e?.target?.value)} className='flex w-full border justify-start gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 h-11'  required />
                    {Submit&&!EmailValidator(EnteredEmail)&&<p className='text-sm p-2 text-red-600'>Please enter a valid email</p>}
                  </div>

                  <div className="mt-4 flex items-center justify-end">
                    <button
                      type="button"
                      onClick={e=>{setIsOpen(false);setEnteredEmail('')}}
                      className="btn btn-primary text-sm parimarybtn  rounded-lg mr-2" 
                    >
                    Cancel
                    </button>
                    <button
                      type="submit"
                      className="!px-4 text-sm font-medium text-primary hover:text-white hover:no-underline h-10 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
                     
                    >
                    Send
                    </button>
                  </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>



        </Layout>
    );
};

export default Html;
