import React, { useState } from 'react';

const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const seasons = ['spring', 'summer', 'autumn', 'winter'];

const SeasonForm = ({ selectedMonths, setSelectedMonths }) => {
    // State to track selected months for each season


    // Handler for checkbox change
    const handleCheckboxChange = (season, month) => {
        setSelectedMonths(prevState => {
            const isSelected = prevState[season].includes(month);

            return {
                ...prevState,
                [season]: isSelected
                    ? prevState[season].filter(m => m !== month)
                    : [...prevState[season], month]
            };
        });
    };

    return (
        <div className='mt-2'>
            <h2 className='text-xl mb-3 font-semibold'>Season</h2>
            <div className='border border-gray p-4 rounded-md'>
                {seasons.map(season => (
                    <div key={season} className=' mb-3 gap-4 grid grid-cols-12 border-b border-gray pb-1'>
                        <h2 className='w-20 capitalize col-span-1 font-semibold'>{season}</h2>
                        <div className='col-span-11 flex flex-wrap gap-2'>
                        {months.map(month => (
                            <label key={month} className='  px-2 cursor-pointer'>
                                <input
                                    type="checkbox"
                                    className='cursor-pointer'
                                    disabled={seasons.some(s => s !== season && selectedMonths[s].includes(month))}
                                    checked={selectedMonths[season].includes(month)}
                                    onChange={() => handleCheckboxChange(season, month)}
                                />
                                {month}
                            </label>
                        ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SeasonForm;
